import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";

const BusinessCoachBrightonPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        heroImg: file(relativePath: { eq: "11.jpg" }) {
          publicURL
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        louis: file(relativePath: { eq: "testimonials/louis-jones.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
        julie: file(relativePath: { eq: "testimonials/pier-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        antoinette: file(
          relativePath: { eq: "testimonials/antoinette-chappell.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400)
          }
        }
      }
    `
  );
  const siteUrl = data.site.siteMetadata.siteUrl;
  const schemaImage = data.heroImg.publicURL;
  const title = "Business Coach in Brighton";
  const description =
    "Business coaching Brighton can help you benefit with making decisions, putting plans in place or overcoming obstacles.";
  const heroImage = data.heroImg.childImageSharp.fluid;
  const louis = data.louis.childImageSharp.gatsbyImageData;
  const antoinette = data.antoinette.childImageSharp.gatsbyImageData;
  const julie = data.julie.childImageSharp.gatsbyImageData;
  const ogImg = `${siteUrl}${schemaImage}`;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Business Coaching",
        item: {
          url: `${siteUrl}/business-coaching`,
          id: `${siteUrl}/business-coaching`,
        },
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "Brighton",
        item: {
          url: `${siteUrl}/business-coaching/brighton`,
          id: `${siteUrl}/business-coaching/brighton`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={title}
        description={description}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/business-coaching/brighton`,
          title: `${title}`,
          description: `${description}`,
          images: [
            {
              url: `${ogImg}`,
              width: 1920,
              height: 1080,
              alt: "Business Coaching in Brighton",
            },
          ],
        }}
      />
      <Hero
        isDarken={true}
        backgroundImage={heroImage}
        title="Business Coach Brighton"
        description1="Business coaching for self-employed business owners in Brighton"
        description2=""
      />
      <section className="py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary pb-3">
                Business coaching for self-employed business owners in Brighton.
              </h2>

              <p>Are you looking for support from a business coach? </p>
              <p>
                I’m Jason Cornes; as an experienced business coach, it’s my job
                to help you:
              </p>

              <ul className="pl-4">
                <li>
                  Take your small business to the next level, whether that’s in
                  terms of your enjoyment in running it, work/life balance,
                  profitability, scalability or even sellability.
                </li>
                <li>
                  Take a fresh look at how you are thinking, feeling or behaving
                  and look at alternative ways that could bring different or
                  improved results.
                </li>
                <li>
                  Overcome the fears or self-limiting beliefs that hold you
                  back, those restraints that knock your self-confidence and
                  prevent you from becoming the business owner you want to be.
                </li>
                <li>
                  And finally, agree on a vision and put plans in place to
                  become more efficient and profitable as a business.
                </li>
              </ul>
              <p>
                Whatever stage you’re at in your business journey, one thing is
                usually for certain; you’d benefit from some help and support to
                either make a decision, put plans in place or overcome
                obstacles.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-primary text-white py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">Brighton - Business by the sea</h2>

              <p>
                As they say, it’s lonely at the top, and it’s for just this
                reason why so many businesses in Brighton are turning to my
                ‘hidden business partner services’ to get the tailor made
                support they desperately need to make progress.
              </p>
              <p>
                You’d be surprised that there isn’t more specialised support
                like this out there; after all, there’s such a mix of
                businesses, people and cultures across Brighton and Hove. A
                patchwork quilt of values, beliefs and life-experiences down
                here by the sea.
              </p>
              <p>
                But, just like you and your small business are unique, you don’t
                need one-size-fits-all, cookie-cutter advice.
              </p>
              <p>
                Your challenges, just like this great city, are unique and often
                complex. You need the sort of bespoke, unbiased support, advice
                and coaching that you’ll only get from a 1-2-1 relationship with
                a business coach with many years of self-employed experience
                under their belt.
              </p>
              <p>
                This is where I step in. I don’t tell you what to do like a
                consultant. Instead, I work with you, empathising, helping,
                supporting and guiding you along the path to success. Helping
                you to overcome your struggles while maximising your personal
                potential. Keeping you focused and holding you accountable.
              </p>
            </Col>
          </Row>
          <Row className="text-center pt-4 book-your-free-consultation-btn">
            <Col>
              <Button
                variant="outline-light"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Book a discovery meeting
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">
                Read how I’ve helped other local businesses here in Brighton.
              </h2>
              <p>
                Take Julie who practices as a life coach within the city. She
                approached me wanting to grow the number of clients she helps
                but wasn’t sure how to go about it. Julie wrote the following
                testimonial about my services:
              </p>
            </Col>
          </Row>

          <HorizontalTestimonial
            name="Julie – Life Coach in the Brighton Area."
            review="Jason has a take-charge style and is kind and humorous. There is
          nothing ‘off the shelf’ about his approach. He really wants you to
          succeed and will do everything he can to be supportive. Being a sole
          trader it was so nice and such a relief to be able to share with
          someone who I trust and knows what he is talking about. I was
          concerned that Jason would view me as a competitor, but when I
          contacted him but he was very open and happy to meet.Jason is helping
          me grow my coaching business and we say in the industry that a coach
          who can coach coaches is good at what they do! Thank you Jason"
            showLinkedIn="d-none"
            profilePic={julie}
          />
          <Row>
            <Col>
              <p>
                Or Louis who is a local physiotherapist with many clients from
                Brighton. I’m delighted to be working with Louis to grow his
                small business. He was good enough to write the following review
                shortly after we started working together:
              </p>
            </Col>
          </Row>
          <HorizontalTestimonial
            name="Louis Jones – Chartered Physiotherapist"
            review="I have only been working with Jason a short time, approximately a
          month, and in 2 sessions with Jason, he has challenged some limiting
          beliefs I have about my business, which were really holding me back,
          and it’s completely transformed my confidence. In only 2 sessions I
          have implemented his valuable advice into action points, and have seen
          massive positive results in my cash flow and also a growth in
          business. Jason really pushes me to step outside of my comfort zone
          and become a better version of me, in order to grow and take my
          business where I want it to be. I will be recommending Jason to all
          business owners."
            profilePic={louis}
            linkedIn="https://www.linkedin.com/in/louisljones/"
          />
        </Container>
      </section>
      <section className="pt-5">
        <Container>
          <Row>
            <Col>
              <h3 className="pb-3">Another local testimonial:</h3>
              <p>
                And last but not least please meet Antoinette from Arc
                Copywriting and Translations Services in Brighton who says:
              </p>
            </Col>
          </Row>
          <HorizontalTestimonial
            name="Antoinette Chappell. Managing Director. ARC Writing and Translation Services."
            review="I have been working with Jason for six months and in that time I have
          learnt so much and developed a lot as a person and business owner.
          What do I like best about working with Jason? His approach to business
          mentoring, which is both friendly and professional and full of
          valuable insights into how to successfully run and grow a business.
          Jason has finely-tuned listening skills and an ability to input
          information, process it and then output a suggestion that I would
          never have considered. This means he can drop a career-changing
          bombshell that ignites a spark that leads to a revolutionary new idea
          that will transform your life and business forever. He challenges my
          perception of what I can and can’t achieve, stretching the boundaries
          of what I thought was possible to the point that the sky is literally
          the limit! Every time I meet him I come away with my mind buzzing with
          new exciting ideas about how to improve my business in ways that seem
          obvious afterwards, but would never have occurred to me on my own. I
          value his contribution to my business very highly and would happily
          recommend his services to other businesses that need a fresh approach
          and guidance from someone with extensive experience of a wide variety
          of businesses from different sectors"
            profilePic={antoinette}
            linkedIn="https://www.linkedin.com/in/antoinette-chappell-b0a83812a/"
          />
        </Container>
      </section>
      <section className="bg-tertiary text-white py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="pb-3">
                Ready to get the support your Brighton business needs?
              </h2>
              <p>
                I love coaching the self-employed no matter what type of product
                or services they offer and any one or two-director businesses
                with or without staff. My clients include ‘creatives’ of which
                there are many in Brighton to all types of trades, health and
                wellness, professional services, and even entrepreneurs in the
                charitable or not for profit sector.
              </p>

              <p>I’d love to speak to you about adding you to this list.</p>
              <p>
                Coaching can take place face-to-face here in Brighton (I live
                along the coast near Eastbourne), or we can meet remotely via
                Zoom or Skype. A mixture of both works well too.
              </p>
              <p>
                If you know there are areas of your business that could be
                improved, or you’re not performing at your best - business
                coaching tailor made for you will offer an ideal solution and
                could be just the answer you are looking for. Click below to
                book an introductory call with me.
              </p>
            </Col>
          </Row>
          <Row className="text-center pt-4">
            <Col>
              <Button
                variant="outline-light"
                className="cta-btn cta-btn-gtm"
                size="lg"
                as={Link}
                to="/contact-us"
              >
                Get in Touch
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <CtaBanner
        headline="Business Coaching in Brighton"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
    </Layout>
  );
};
export default BusinessCoachBrightonPage;
